<template>

    <!-- Case Start -->
    <div class="container-fluid bg-light pb-5" id="plan">
        <div class="container py-5">
            <div class="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style="max-width: 500px;">
                <div class="btn btn-sm border rounded-pill text-primary px-3 mb-3">Nuestros Planes</div>
                <h1 class="mb-4">Elige el plan perfecto para tu negocio</h1>
            </div>
            <div class="row 1875remmy-5 text-center">
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="card mb-4 rounded-3 shadow-sm">
                            <div class="card-header py-3">
                                <h4 class="my-0 fw-normal">Planes Diseño Web</h4>
                            </div>
                            <div class="card-body">
                                <ul class="list-unstyled mt-3 mb-4 text-start">
                                    <li><i class="fas fa-check-circle text-success"></i> Soporte.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Diseño (Templates Divi).</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Planes anuales.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Certificado SSL.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Hosting.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Actualizaciones a la medida.
                                    </li>
                                    <li><i class="fas fa-check-circle text-success"></i> Formulario de contacto.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Chat multi agente con el
                                        cliente.</li>
                                    <li>&nbsp;</li>
                                    <li>&nbsp;</li>
                                    <li>&nbsp;</li>
                                    <li><i class="fas fa-bullseye text-danger"></i><strong> Nota: </strong>Nuestros
                                        planes estan diseñados para satisfacer sus necesidades.</li>

                                </ul>
                                <router-link class="w-100 btn btn-lg btn-primary" to="/diseno">Ver Planes</router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="card mb-4 rounded-3 shadow-sm">
                            <div class="card-header py-3 text-white">
                                <h4 class="my-0 fw-normal">Desarrollo a la Medida</h4>

                            </div>
                            <div class="card-body">
                                <ul class="list-unstyled mt-3 mb-4 text-start">
                                    <li><i class="fas fa-check-circle text-success"></i> Aplicaciones híbridas.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> CallCenter.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Diseño web.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Módulos Dolibar.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Recursos humanos.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Omnicanalidad.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Inventarios.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Ticket de soporte y atención.
                                    </li>
                                    <li><i class="fas fa-check-circle text-success"></i> CRM a la medida.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Todo lo que puedas imaginar.
                                    </li>
                                    <li>&nbsp;</li>
                                    <li><i class="fas fa-bullseye text-danger"></i><strong> Nota: </strong>Contactenos y Sus ideas se
                                        pueden hacer realidad.</li>
                                </ul>
                                <router-link class="w-100 btn btn-lg btn-primary" to="/desarrollo">Leer más</router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="card mb-4 rounded-3 shadow-sm">
                            <div class="card-header py-3">
                                <h4 class="my-0 fw-normal">Planes Hosting</h4>
                            </div>
                            <div class="card-body">
                                <ul class="list-unstyled mt-3 mb-4 text-start">
                                    <li><i class="fas fa-check-circle text-success"></i> Soporte.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Tecnología SSD.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Correos electrónicos
                                        ilimitados.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Certificado SSL gratis.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Backup.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Compatible con Wordpress.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Instalador de aplicaciones.
                                    </li>
                                    <li><i class="fas fa-check-circle text-success"></i> Seguridad.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Licencia Divi.</li>
                                    <li>&nbsp;</li>
                                    <li>&nbsp;</li>
                                    <li><i class="fas fa-bullseye text-danger"></i><strong> Nota: </strong>Todos los
                                        planes se activan dentro de las 24 horas.</li>

                                </ul>
                                <router-link class="w-100 btn btn-lg btn-primary" to="/hosting">Ver Planes</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </div>



    <!-- Case End -->
</template>

<script>
import { RouterLink } from 'vue-router';
export default {
    name: "PlanesHome",
    components: {
        RouterLink
    },


}
</script>